<template>
    <section>
        <tabs :tabs="tabs" class="border-bottom" />
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 169px)">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    computed: {
        tabs(){
            return [
                { titulo: 'Mercadopago', ruta: 'admin.pasarela-pagos.mercadopago' },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>

</style>
